import React, { useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import BlogPost from "../components/BlogPost";
import Down from "../../assets/images/down.svg";

export const pageQuery = graphql`
  query BlogQuery {
    prismicSeo(data: { name: { eq: "Mosano | Blog" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    allPrismicPost(sort: { fields: data___date, order: DESC }) {
      nodes {
        data {
          author {
            document {
              ... on PrismicTeamMember {
                data {
                  name
                  photo {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 90
                          tracedSVGOptions: {
                            background: "#1e22aa"
                            color: "#333"
                          }
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          canonical_url {
            url
          }
          date
          description
          cover {
            alt
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
          content {
            html
          }
          title
          intro
        }
        tags
        uid
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const Blog = (props: any) => {
  const allPosts = props.data?.allPrismicPost?.nodes || [];
  const [selected, setSelected] = useState(0);
  const [hide, setHide] = useState(true);

  const tags = [
    ...new Set(
      allPosts.reduce((tgs, blogPost) => [...tgs, ...blogPost.tags], [])
    ),
  ].sort((a, b) => (a > b ? 1 : -1)) as Array<string>;
  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="hero-blog">
        <div className="container-hero-blog">
          <h1 className="heading white center">Blog</h1>
          <p className="p1 white center max">
            Catch up with our latest articles
          </p>
        </div>
      </div>
      <div className="blog">
        <div className="container-blog">
          <div className="topic-row">
            <div className="row">
              <h2 className="cap-blog">You are seeing:</h2>
              <button className="topic-selected" onClick={() => setHide(!hide)}>
                {tags[selected - 1] === undefined
                  ? "All Topics"
                  : tags[selected - 1]}
                <img
                  className={`blog__topic-chevron${
                    hide ? "" : " blog__topic-chevron-open"
                  }`}
                  src={Down}
                  alt="dropdown button"
                  style={{ marginLeft: 8 }}
                />
              </button>
            </div>
            <div
              className={`topic-selector ${
                !hide ? "blog__topic-selector_show" : ""
              }`}
            >
              <div>
                <button
                  id="topic-index-0"
                  className={`topic-item ${
                    selected == 0 ? "selected" : "blog-color-primary"
                  }`}
                  onClick={() => {
                    setSelected(0);
                    setHide(!hide);
                  }}
                >
                  {" "}
                  All Topics
                </button>
                {tags
                  .sort((a, b) => (a > b ? 1 : -1))
                  .map((tag, index) => (
                    <button
                      id={`topic-index-${index + 1}`}
                      className={`topic-item ${
                        selected == index + 1
                          ? "selected"
                          : "blog-color-primary"
                      }`}
                      key={`tag#${index + 1}`}
                      onClick={() => {
                        setSelected(index + 1);
                        setHide(true);
                      }}
                    >
                      {tag}
                    </button>
                  ))}
              </div>
            </div>
          </div>
          <div className="blog-row">
            {allPosts.map((post, index) => {
              if (index == 0) {
                return (
                  <BlogPost
                    key={`post-${index}`}
                    source={post.data}
                    tags={post.tags}
                    path={post.uid}
                    featured
                    selected={tags[selected - 1]}
                  />
                );
              }
              if (index > 0) {
                return (
                  <BlogPost
                    key={`post-${index}`}
                    source={post.data}
                    tags={post.tags}
                    path={post.uid}
                    selected={tags[selected - 1]}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
