function readTime(text): string {
  if (!text) {
    return "";
  }
  const wordsPerMinute = 200;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;
  const time = Math.ceil(minutes);
  return `${time} min read`;
}

export default readTime;
