import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const Avatar = (props: any) => (
  <div className="blog-post-autor">
    <GatsbyImage
      image={props.image}
      className="avatar-image"
      alt={`${props.name} author portrait`}
    />
    <div className="autor-into">
      <span className="autor-name blog-color-primary">{props.name}</span>
      <div className="author__row">
        <span className="autor-post-date">{props.date}</span>
        {/* <span className="autor-post-separator">&#183;</span> */}
        <span className="autor-post-time">{props.readTime}</span>
      </div>
    </div>
  </div>
);

export default Avatar;

Avatar.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.object,
  readTime: PropTypes.string,
};
