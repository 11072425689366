import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Avatar from "../Avatar";
import readTime from "../../utils/read-time";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const checkSelection = (selected, tags) => {
  let res = 0;
  if (selected == undefined) {
    return 1;
  }
  tags.forEach((tag) => {
    if (tag == selected) {
      res += 1;
    }
  });
  return res;
};

const SINGLE_LIST_MAX_SCREEN_SIZE = 800;
const DOUBLE_LIST_SM_MAX_SCREEN_SIZE = 1000;
const MIN_HEIGHT_FOR_DOUBLE_SM = 850;

const BlogPost = (props: any) => {
  const dimensions = useWindowDimensions();
  const [postListType, setPostListType] = useState<
    "single" | "double-sm" | "double-lg"
  >();

  useEffect(() => {
    if (dimensions) {
      const screenWidth = dimensions.width;
      const screenHeight = dimensions.height;

      if (screenWidth < SINGLE_LIST_MAX_SCREEN_SIZE) {
        setPostListType("single");
        return;
      }

      if (
        screenWidth <= DOUBLE_LIST_SM_MAX_SCREEN_SIZE &&
        screenHeight > MIN_HEIGHT_FOR_DOUBLE_SM
      ) {
        setPostListType("double-sm");
        return;
      }

      setPostListType("double-lg");
    }
  }, [dimensions]);

  return (
    <>
      <div
        className={`blog-post ${props.featured ? "featured" : "not-featured"} ${
          checkSelection(props.selected, props.tags) > 0
            ? "fade-in"
            : "fade-out"
        }`}
      >
        <h2 className="blog-post-topic">
          {props.tags.map((item, index) => (
            <span
              id={`blog-post-topic-index-${index + 1}`}
              key={`blog-post-topic-index-${index + 1}`}
            >
              {item}
              {index + 1 < props.tags.length ? ", " : ""}
            </span>
          ))}
        </h2>
        <Link className="blog-link" to={`/blog/${props.path}`}>
          {typeof props.source.cover.localFile.childImageSharp
            .gatsbyImageData !== "undefined" && postListType ? (
            <GatsbyImage
              image={
                props.source.cover.localFile.childImageSharp.gatsbyImageData
              }
              alt={props.source.cover.alt}
              className={`blog-post-image ${
                props.featured
                  ? `featured-post-list-${postListType}`
                  : `post-list-${postListType}`
              }`}
            />
          ) : (
            ""
          )}
          <div className="blog-post-info">
            <h2 className="blog-post-title blog-color-primary">
              {props.source.title}
            </h2>
            <p className="blog-post-intro blog-color-primary">
              {props.source.intro}
            </p>
            {typeof props.source.author.document.data.photo.localFile
              .childImageSharp.gatsbyImageData !== "undefined" ? (
              <Avatar
                name={props.source.author?.document.data.name}
                //date={props.source.date}
                image={
                  props.source.author.document.data.photo.localFile
                    .childImageSharp.gatsbyImageData
                }
                readTime={readTime(
                  props?.source?.content?.html?.replace(/<\/?[^>]+(>|$)/g, "")
                )}
              />
            ) : (
              ""
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlogPost;

BlogPost.propTypes = {
  source: PropTypes.object,
  tags: PropTypes.array,
  featured: PropTypes.bool,
  path: PropTypes.string,
  selected: PropTypes.string,
};
